<template>
    <Stack :space="30">
        <Heading level="2" color="comp"> Verkliga huvudmän </Heading>
        <Columns>
            <Column :width="10">
                <button class="button is-info is-small is-rounded" @click="getCM1()" :disabled="isCompleted" :class="[{ 'is-loading': cm1Loading }]">
                    CM1
                </button>
            </Column>
            <Column :width="10" v-if="isStockCompany">
                <button class="button is-info is-small is-rounded" :disabled="isCompleted" @click="getBeneficiaryCheckbiz()" :class="[{ 'is-loading': checkbizloading }]">
                    Hämta från Checkbiz
                </button>
            </Column>
            <Column :width="25" />
            <Column :width="40" v-if="isStockCompany"><TextBase size="medium" weight="bold">Från Checkbiz</TextBase></Column>
            <Column>
                <Stack :space="10">
                    <TextBase style="align-self:flex-end" size="small" weight="bold">Svaren inhämtade:</TextBase>
                    <TextBase style="align-self:flex-end" size="small" weight="light">{{ companyInfo.onboardingClients.createDate }}</TextBase>
                </Stack>
            </Column>
        </Columns>
        <Stack :space="10">
            <Columns align-y="top">
                <Column>
                    <Stack :space="10">
                        <Columns :space="5" align-y="top">
                            <Column :width="20"><TextBase size="small" weight="bolder">Namn</TextBase></Column>
                            <Column :width="15"><TextBase size="small" weight="bolder">Personnummer</TextBase></Column>
                            <Column :width="8"><TextBase size="small" weight="bolder">Andel</TextBase></Column>
                            <Column :width="6"><TextBase size="small" weight="bolder">PEP</TextBase></Column>
                            <Column :width="9"><TextBase size="small" weight="bolder">PEP(CM1)</TextBase></Column>
                            <Column :width="13"><TextBase size="small" weight="bolder">Sanktion</TextBase></Column>
                            <Column :width="5"><TextBase size="small" weight="bolder">ID</TextBase></Column>
                        </Columns>
                        <Control v-bind:key="Id + option.Id + 'D'" v-for="(option, Id) in ownerInformation">
                            <Columns :space="5" align-y="top">
                                <Column :width="20">{{ option.firstName }} {{ option.lastName }}</Column>
                                <Column :width="15">{{ option.personalNumber }}</Column>
                                <Column :width="8">{{ option.controlPercentage }}%</Column>
                                <Column :width="6">{{ getEnum.yesNoOption[option.isPoliticallyConnected] }}</Column>
                                <Column :width="9">{{ getEnum.yesNoOption[option.isPEPCm1] }}</Column>
                                <Column :width="13">{{ getEnum.yesNoOption[option.isSanctionCm1] }}</Column>
                                <Column :width="3">{{ isID(Id) }}</Column>
                                <span @click="openItem(Id)">
                                    <Column :width="10">
                                        <i class="fas fa-chevron-down" :style="option.accordianArrowStyle" />
                                    </Column>
                                </span>
                            </Columns>
                            <Stack style="background: #80808061; width:80%" v-bind:class="{ active: option.accordianArrowStyle == 'transform: rotate(180deg)' }">
                                <Control v-if="option.isopen">
                                    <Columns align-y="top">
                                        <Column :width="20"><TextBase size="xsmall" weight="light">Ägs:</TextBase></Column>
                                        <Column :width="5">
                                            <TextBase size="xsmall" weight="light">{{ getEnum.ownershipDetailOptions[option.controlBy] }}</TextBase>
                                        </Column>
                                        <Column v-if="option.controlBy == 2">
                                            <TextBase size="xsmall" weight="light">(Org no: {{ option.organizationNumber }})</TextBase>
                                        </Column>
                                    </Columns>
                                    <Columns align-y="top" v-if="isStockCompany">
                                        <Column :width="20"><TextBase size="xsmall" weight="light">Medlem i styrelsen:</TextBase></Column>
                                        <Control v-if="!option.addedByAdmin">
                                            <Column :width="5">
                                                <TextBase size="xsmall" weight="light">{{ getEnum.yesNoOption[option.isBoardMember] }}</TextBase>
                                            </Column>
                                            <Column v-if="option.isBoardMember">
                                                <TextBase size="xsmall" weight="light">({{ getEnum.boardOptions[option.boardRole] }})</TextBase>
                                            </Column>
                                        </Control>
                                        <Control v-else>
                                            <Column :width="40">
                                                <Dropdown :options="YesNoOptions" :value="option.isBoardMember" @on-change="value => (option.isBoardMember = value)" />
                                            </Column>
                                        </Control>
                                    </Columns>
                                    <Columns align-y="top">
                                        <Column :width="20"><TextBase size="xsmall" weight="light">PEP:</TextBase> </Column>
                                        <Column :width="5">
                                            <TextBase size="xsmall" weight="light">{{ getEnum.yesNoOption[option.isPoliticallyConnected] }}</TextBase>
                                        </Column>
                                        <Column v-if="option.isPoliticallyConnected">
                                            <TextBase size="xsmall" weight="light" style="margin-bottom: 10px">
                                                (Typ av utsatt ställning : {{ getEnum.typeOfPoliticalPosition[option.typeOfPoliticalPosition] }})</TextBase
                                            >
                                            <TextBase size="xsmall" weight="light">(Förhållande till personen : {{ getEnum.politicalRelationship[option.politicalRelationship] }})</TextBase>
                                        </Column>
                                    </Columns>
                                    <Columns align-y="top">
                                        <Column :width="20"><TextBase size="xsmall" weight="light">E-post:</TextBase> </Column>
                                        <Column :width="20" v-if="!option.addedByAdmin">
                                            <TextBase size="xsmall" weight="light">{{ option.email }}</TextBase>
                                        </Column>
                                        <Column :width="40" v-else>
                                            <Input v-model="option.email" :value="option.email" type="email" v-validate="'required|email'" name="E-post" :error="errors.first('E-post')" />
                                        </Column>
                                    </Columns>
                                    <Columns align-y="top">
                                        <Column :width="20"><TextBase size="xsmall" weight="light">Huvudmän:</TextBase> </Column>
                                        <Column :width="20">
                                            <CheckBox v-model="option.isOwner" />
                                        </Column>
                                    </Columns>
                                    <Columns align-y="top">
                                        <Column :width="20"><TextBase size="xsmall" weight="light">Clientadmin:</TextBase> </Column>
                                        <Column :width="20">
                                            <CheckBox v-model="option.isClientAdmin" />
                                        </Column>
                                    </Columns>
                                    <Columns v-if="idExists(Id)">
                                        <Column>
                                            <a @click="toggleFileDisplay(Id)">Granska ID/LEG </a>
                                        </Column>
                                    </Columns>
                                    <Columns v-if="idExists(Id)">
                                        <ShowImage style="width: 100%;" :content-url="companyInfo.file[Id]" :image-type="companyInfo.fileType[Id]" :show="showFile[Id]" />
                                    </Columns>
                                    <Columns v-else>
                                        <FileUpload @file-uploaded="handleFileUploads" :file-name="fileName" />
                                    </Columns>
                                    <Columns>
                                        <button class="button is-light is-small" @click="cancel(Id)">
                                            Avbryt
                                        </button>
                                        <!-- v-if="showSaveButton(option, Id)" -->
                                        <button :disabled="isCompleted" class="button is-small is-success" @click="saveByAdmin(Id)" :class="[{ 'is-loading': saveLoading }]">
                                            Save
                                        </button>
                                        <Column />
                                        <button class="button is-danger is-small" :disabled="isCompleted" :class="[{ 'is-loading': deleteLoading }]" @click="deleteOwner(Id)">
                                            Radera
                                        </button>
                                    </Columns>
                                </Control>
                            </Stack>
                        </Control>
                        <button class="button is-info is-small is-rounded" @click="goback()" style="margin-top:150px">
                            Tillbaka
                        </button>
                    </Stack>
                </Column>
                <Column v-if="isStockCompany">
                    <Stack :space="10">
                        <Columns :space="5" align-y="top">
                            <Column :width="25"><TextBase size="small" weight="bolder">Namn</TextBase></Column>
                            <Column :width="20"><TextBase size="small" weight="bolder">Personnummer</TextBase></Column>
                            <Column :width="30"><TextBase size="small" weight="bolder">Andel</TextBase></Column>
                        </Columns>
                        <Control v-bind:key="Id + option.Id + 'D'" v-for="(option, Id) in beneficiaryCheckbiz">
                            <Columns :space="5" align-y="top">
                                <Column :width="25">{{ option.fullName }}</Column>
                                <Column :width="20">{{ option.ssn }}</Column>
                                <Column :width="30">{{ getEnum.intervalOptions[option.interval] }}</Column>
                                <Column :width="5">
                                    <span @click="openBeneficiary(Id)" style="cursor: pointer">
                                        <i class="fas fa-chevron-down" :style="option.accordianArrowStyle" />
                                    </span>
                                </Column>
                                <Column :width="3" v-if="!option.loading">
                                    <i @click="addOwner(Id)" style="cursor: pointer" class="fas fa-user-plus" :class="[{ 'fa-disabled': disableAddIcon(Id) }]" />
                                </Column>
                                <Column :width="5" v-else>
                                    <div>
                                        <i class="fas fa-circle-notch fa-spin" />
                                    </div>
                                </Column>
                            </Columns>
                            <Stack style="background: #80808061; width:78%" v-bind:class="{ active: option.accordianArrowStyle == 'transform: rotate(180deg)' }">
                                <Control v-if="option.isopen">
                                    <Control v-bind:key="Id + option.Id + 'D'" v-for="(option, Id) in option.controls">
                                        <TextBase size="xsmall" weight="light">- {{ getEnum.controlOptions[option] }}</TextBase>
                                    </Control>
                                </Control>
                            </Stack>
                        </Control>
                    </Stack>
                </Column>
            </Columns>
        </Stack>
    </Stack>
</template>
<script>
import moment from "moment";
import "moment/locale/se";
import ShowImage from "../../../components/showImage.vue";
import Stack from "../../../components/layout/Stack.vue";
import Heading from "../../../components/content/Heading.vue";
import Columns from "../../../components/layout/Columns.vue";
import Column from "../../../components/layout/Column.vue";
import TextBase from "../../../components/content/Text.vue";
import Control from "../../../components/layout/Control.js";
import Input from "../../../components/baseInput";
import Dropdown from "../../../components/dropdown.vue";
import CheckBox from "../../../components/checkbox.vue";
import FileUpload from "../../../components/fileUpload";
import Enum from "../onboarding.enum.js";
import Api from "../onboarding.api.js";
export default {
    props: ["onboardClientId", "companyInfo"],
    components: {
        Stack,
        Columns,
        Column,
        Heading,
        TextBase,
        Control,
        ShowImage,
        Input,
        Dropdown,
        FileUpload,
        CheckBox,
    },
    data() {
        return {
            file: "",
            fileName: "",
            YesNoOptions: [
                { value: false, name: "Nej" },
                { value: true, name: "Ja" },
            ],
            beneficiaryCheckbiz: {},
            deleteLoading: false,
            saveLoading: false,
            ownerInformation: [],
            cm1Loading: false,
            checkbizloading: false,
            showFile: [false, false, false, false, false, false, false, false, false, false, false, false],
        };
    },
    watch: {
        companyInfo: {
            immediate: true,
            async handler() {
                this.getOwners();
                this.assignBeneficiaryCheckbiz(this.companyInfo.beneficiaryCheckbiz);
                this.companyInfo.onboardingClients.createDate = moment(String(this.companyInfo.onboardingClients.createDate)).format("YYYY-MM-DD");
            },
        },
    },
    methods: {
        saveByAdmin(key) {
            this.$validator.validateAll().then(isValid => {
                if (isValid && this.errors.items.length == 0) {
                    let formData = new FormData();
                    this.companyInfo.onboardingClients.onboardOwners[key] = this.ownerInformation[key];
                    this.$delete(this.companyInfo.onboardingClients.onboardOwners[key], "accordianArrowStyle");
                    formData.append("file", this.file);
                    let rawData = JSON.stringify(this.companyInfo.onboardingClients.onboardOwners[key]);
                    formData.append("onboardData", rawData);
                    var result = this.saveAsync(formData, key);
                    if (result) {
                        if (this.ownerInformation[key].isPEPCm1 == true) {
                            this.ownerInformation[key].isPoliticallyConnected = true;
                            this.companyInfo.onboardingClients.onboardOwners[key] = true;
                        }
                        this.$set(this.ownerInformation[key], "accordianArrowStyle", "transform: rotate(180deg)");
                    }
                }
            });
        },
        async saveAsync(formData, key) {
            this.saveLoading = true;
            var result = await Api.saveByAdmin(formData);
            if (result) {
                this.saveLoading = false;
                this.companyInfo.file[key] = result.file;
                this.companyInfo.fileType[key] = result.fileType;
            }
            return result;
        },
        async addOwner(key) {
            this.$set(this.beneficiaryCheckbiz[key], "loading", true);
            var result = await Api.addCheckBizOwner(this.beneficiaryCheckbiz[key].ssn, this.beneficiaryCheckbiz[key].fullName, this.companyInfo.onboardingClients.onboardClientId);
            if (result != undefined) {
                this.$set(this.beneficiaryCheckbiz[key], "loading", false);
                this.companyInfo.onboardingClients.onboardOwners = result;
                this.getOwners();
            }
        },
        async getBeneficiaryCheckbiz() {
            this.checkbizloading = true;
            if (this.ownerInformation.length > 0)
                var result = await Api.fetchBeneficiaryCheckbiz(this.companyInfo.onboardingClients.organizationNumber, this.companyInfo.onboardingClients.onboardClientId);
            else this.checkbizloading = false;

            if (result.length == 0) {
                this.$toasted.show("Inget resultat från checkbiz", {
                    theme: "bubble",
                    duration: 4000,
                    position: "bottom-center",
                    type: "warning",
                    keepOnHover: true,
                    action: {
                        text: "Cancel",
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            }

            if (result || result == null) {
                this.checkbizloading = false;
                this.companyInfo.beneficiaryCheckbiz = result;
                this.assignBeneficiaryCheckbiz(result);
            }
        },
        async getCM1() {
            this.cm1Loading = true;
            if (this.ownerInformation.length > 0) var result = await Api.getCm1(this.companyInfo.onboardingClients.onboardClientId);
            else this.cm1Loading = false;
            if (result) {
                for (var i in this.ownerInformation) {
                    this.$set(this.companyInfo.onboardingClients.onboardOwners[i], "isPEPCm1", result[i].isPEPCm1);
                    this.$set(this.companyInfo.onboardingClients.onboardOwners[i], "isSanctionCm1", result[i].isSanctionCm1);
                    this.$set(this.ownerInformation[i], "isPEPCm1", result[i].isPEPCm1);
                    this.$set(this.ownerInformation[i], "isSanctionCm1", result[i].isSanctionCm1);
                }
                this.cm1Loading = false;
            }
        },
        goback() {
            this.$router.push(`/onboardingList/`);
        },
        async deleteOwner(key) {
            this.deleteLoading = true;
            var onboardId = this.ownerInformation[key].onboardClientId;
            var ownerId = this.ownerInformation[key].onboardOwnerId;
            var result = await Api.deleteOwnerInfo(onboardId, ownerId);
            if (result) {
                this.deleteLoading = false;
                this.ownerInformation.splice(key, 1);
                this.companyInfo.onboardingClients.onboardOwners.splice(key, 1);
            }
        },
        cancel(key) {
            this.ownerInformation[key].isopen = false;
            if (this.ownerInformation[key].isopen) {
                this.ownerInformation[key].accordianArrowStyle = "transform: rotate(180deg)";
            } else {
                this.ownerInformation[key].accordianArrowStyle = "transform: rotate(0deg)";
            }
            this.showFile[key] = false;
        },
        toggleFileDisplay(key) {
            this.$set(this.showFile, key, !this.showFile[key]);
        },
        openItem: function(key) {
            this.ownerInformation[key].isopen = !this.ownerInformation[key].isopen;
            if (this.ownerInformation[key].isopen) {
                this.ownerInformation[key].accordianArrowStyle = "transform: rotate(180deg)";
            } else {
                this.ownerInformation[key].accordianArrowStyle = "transform: rotate(0deg)";
            }
        },
        isID(Id) {
            if (this.companyInfo.file[Id] != undefined && this.companyInfo.file[Id] != "") {
                return "Ja";
            } else return "Nej";
        },
        openBeneficiary(key) {
            this.beneficiaryCheckbiz[key].isopen = !this.beneficiaryCheckbiz[key].isopen;
            if (this.beneficiaryCheckbiz[key].isopen) {
                this.beneficiaryCheckbiz[key].accordianArrowStyle = "transform: rotate(180deg)";
            } else {
                this.beneficiaryCheckbiz[key].accordianArrowStyle = "transform: rotate(0deg)";
            }
        },
        assignBeneficiaryCheckbiz(result) {
            this.beneficiaryCheckbiz = result;
            for (var i in this.beneficiaryCheckbiz) {
                this.$set(this.beneficiaryCheckbiz[i], "isopen", false);
                this.$set(this.beneficiaryCheckbiz[i], "accordianArrowStyle", "transform: rotate(0deg)");
            }
        },
        getOwners() {
            this.ownerInformation = this.companyInfo.onboardingClients.onboardOwners.map(a => ({ ...a }));
            for (var i in this.ownerInformation) {
                this.$set(this.ownerInformation[i], "isopen", false);
                this.$set(this.ownerInformation[i], "accordianArrowStyle", "transform: rotate(0deg)");
            }
        },
        disableAddIcon(Id) {
            var isExist = this.ownerInformation.filter(l => {
                return l.personalNumber.replace(/-/g, "") == this.beneficiaryCheckbiz[Id].ssn.replace(/-/g, "");
            });
            if (isExist.length > 0) return true;
            else return false;
        },
        idExists(key) {
            if (this.companyInfo.file[key] == "" || this.companyInfo.file[key] == undefined) {
                return false;
            }
            return true;
        },
        handleFileUploads(value) {
            if (value == undefined) {
                this.fileName = "";
                this.file = "";
            } else {
                this.file = value;
                this.fileName = value.name;
            }
        },
        showSaveButton(option, Id) {
            if (option.addedByAdmin || this.companyInfo.file[Id] == undefined || this.companyInfo.file[Id] == "" || this.companyInfo.file[Id] == null) {
                return true;
            }
            return false;
        },
    },
    computed: {
        isCompleted() {
            if (this.companyInfo.onboardingClients.onboardingStatus == 2) {
                return true;
            }
            return false;
        },
        isStockCompany() {
            if (this.companyInfo.onboardingClients.companyType == 1) {
                return false;
            }
            return true;
        },
        getEnum() {
            return Enum;
        },
    },
};
</script>
<style scoped>
.active {
    padding: 10px;
}
.fa-disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
