<template>
    <div :class="{ horizontalRadioList: horizontal }">
        <RadioButton
            :key="entry.id"
            v-for="entry in entries"
            :class="{ horizontalRadioListFixup: horizontal }"
            :checked="selectedIdTemp === entry.value"
            @change="changeId(entry)"
            :value="entry.value"
            :label="entry.name"
            :disabled="entry.disabled"
        />
    </div>
</template>

<script>
/* id & value */

import RadioButton from "./radioButton";

export default {
    props: {
        entries: Array,
        selectedId: { type: [Number, String] },
        horizontal: Boolean,
    },
    components: { RadioButton },
    data() {
        return {
            selectedIdTemp: 0,
        };
    },
    model: {
        event: "change",
    },
    watch: {
        selectedId: {
            immediate: true,
            handler() {
                this.selectedIdTemp = this.selectedId;
            },
        },
        // selectedId() {
        //   console.log("watch=>", this.selectedId);
        //   this.selectedIdTemp = this.selectedId;
        // }
    },
    created() {
        // console.log("1=>", this.selectedId);
    },
    methods: {
        changeId(entry) {
            this.selectedIdTemp = entry.value;

            this.$emit("change", entry);
            // if (this.entries[id] && !this.entries[id].disabled) {
            //   this.selectedIdTemp = id;
            //   this.$emit("change", this.selectedIdTemp);
            // }
        },
        onClick() {
            if (!this.disabled) {
                this.$emit("change", this.value);
            }
        },
        toggleValue(e) {
            if (!this.disabled) {
                if (e.keyCode === 32 || e.keyCode === 13) {
                    e.preventDefault();
                    this.onClick();
                }
            }
        },
        handleFocus() {
            if (!this.disabled) {
                window.addEventListener("keypress", this.toggleValue);
            }
        },
        handleBlur() {
            if (!this.disabled) {
                window.removeEventListener("keypress", this.toggleValue);
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.radio-button-container {
    cursor: pointer;
    display: flex;
    align-items: center;

    &:focus {
        outline: none;
        color: #2eb0f5;
        .radio-button-circle {
            background: #f7f9fb;
        }
    }
}
.error {
    .radio-button-circle {
        background: #fff8f8;
        border-color: #ffbaba;
        color: #ff7878;
    }
    .radio-button-label {
        color: #ff7878;
    }
}
.radio-button-circle {
    margin-right: 10px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 2px #0085c7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.radio-button-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #14abf4;
}
.radio-button-label {
    font-size: 15px;
    /* font-weight: 300; */
}
.horizontalRadioList {
    display: flex;
    flex-direction: row;
    .radio-button {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}

.horizontalRadioListFixup {
    margin-left: 10px;
}
</style>
