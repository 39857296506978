<template>
    <div>
        <el-button ref="firstbutton" style="margin-left: auto;margin-bottom: 10px" size="large" type="primary" @click="uploadingFiles = true">Ladda upp filer</el-button>
        <el-button v-if="selectedIds.length > 0" style="margin-left: 10px;margin-bottom: 10px" size="large" type="danger" @click="removeDocuments">Radera dokument</el-button>
        <div class="card-tail">
            <el-table
                ref="table"
                @row-click="handleRowClick"
                :default-sort="preSelectedSort"
                :data="documentsData"
                @sort-change="sortChange"
                v-loading="$waiting.is('loading')"
                @selection-change="handleSelectionChange"
                :row-class-name="tableRowClassName"
                size="mini"
            >
                <!--                <el-table-column type="selection" width="55" /> -->
                <el-table-column prop="documentId" label="Id" sortable="custom" width="100" />
                <el-table-column prop="fileName" label="Filnamn" sortable="custom" show-overflow-tooltip />
                <el-table-column prop="status" label="Status" sortable="custom" width="130" align="center">
                    <template slot-scope="scope">
                        <el-tag :type="getButtonType(scope.row.uploadedDocumentStatus)">
                            {{ getStatusText(scope.row.uploadedDocumentStatus) }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="clientName" label="Klient" sortable="custom" show-overflow-tooltip />
                <el-table-column prop="supplierName" label="Leverantör" sortable="custom" show-overflow-tooltip />
                <el-table-column prop="createDate" label="Skapad" sortable="custom" width="150">
                    <template slot-scope="scope">
                        <p>
                            {{ formatDate(scope.row.createDate) }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="invoiceDate" label="Faktura datum" sortable="custom" width="150">
                    <template slot-scope="scope">
                        <p>
                            {{ formatDate(scope.row.invoiceDate) }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="dueDate" label="Förfalodatum" sortable="custom" width="150">
                    <template slot-scope="scope">
                        <p>
                            {{ formatDate(scope.row.dueDate) }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="grossAmount" label="Belopp" sortable="custom" width="100" />
                <el-table-column prop="currency" label="Valuta" sortable="custom" width="100" />
                <el-table-column prop="uploadedBy" label="Uppladad av" sortable="custom" show-overflow-tooltip />
                <el-table-column prop="id" width="50">
                    <template slot-scope="scope">
                        <i class="fas fa-lock" v-if="Object.keys(lockedIds).includes(scope.row.documentId.toString())" :title="lockedIds[scope.row.documentId.toString()]" />
                    </template>
                </el-table-column>
            </el-table>
            <Pagination ref="paginationComponent" @change="updateUrlAndGetAll" :total-amount="totalInvoicesNumber" :page-number-prop="pageNumber" />
        </div>
        <el-dialog :visible="uploadingFiles" title="Ladda upp en eller fler filer">
            <div class="set-margin">
                <div>
                    <label>
                        <input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()" />
                    </label>
                    <div class="buttons">
                        <button class="button is-info" v-on:click="addFiles()">Lägga till filer</button>
                        <div v-if="spinner" class="fas fa-spinner fa-pulse" />
                        <div class="tag is-success" v-if="!isNextDisabled">
                            <span class="icon has-text-info">
                                <i class="fas fa-info-circle" />
                            </span>

                            Filer skickas in!
                        </div>
                    </div>
                </div>
                <div class="table-container">
                    <table class="table is-striped is-narrow is-hoverable is-fullwidth" style="width: 100%; margin-top: 20px">
                        <tr>
                            <th style="width: 100px">S.no</th>
                            <th style="width: 400px">Filnamn</th>
                            <th />
                        </tr>
                        <tr :key="key" v-for="(file, key) in files">
                            <td>{{ key + 1 }}</td>
                            <td>{{ file.name }}</td>
                            <td><span class="remove-file" v-on:click="removeFile(key)">Ta bort</span></td>
                        </tr>
                    </table>
                </div>
                <div class="next-btn" />
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="submitFiles()" :loading="$waiting.is('loading')">Ladda up</el-button>
                    <el-button type="error" @click="uploadingFiles = false">Avbryt</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import queryString from "query-string";
import moment from "moment";
import Api from "./documents.api";
import { mapState } from "vuex";

export default {
    components: {
        Pagination: () => import(/* webpackChunkName: "DocumentsPagination" */ "../invoices/components/Pagination.vue"),
    },
    data() {
        return {
            documentsData: [],
            totalInvoicesNumber: 0,
            pageNumber: 1,
            pageSize: 15,
            query: "",
            sortBy: "",
            sortByType: "",
            status: "",
            currency: "",
            dateFrom: "",
            dateTo: "",
            clientId: "",
            loading: true,
            uploadingFiles: false,
            sNo: 1,
            isNextDisabled: true,
            spinner: false,
            lockedIds: [],
            connection: null,
            selectedIds: [],
        };
    },

    mounted() {
        this.$refs.firstbutton.$el.focus();
        const url = process.env.VUE_APP_SIGR_URL + "lockhub";
        const signalR = require("@microsoft/signalr");
        var connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Error)
            .withUrl(url)
            .build();
        connection.on("lockUpdate", lockedIds => {
            this.lockedIds = lockedIds;
        });
        connection.on("remove", id => {
            this.documentsData = this.documentsData.filter(function(row) {
                return row.documentId !== id;
            });
        });
        connection
            .start()
            .then(function() {
                connection.invoke("onConnected");
            })
            .catch(function(err) {
                return console.error(err.toString());
            });
        this.connection = connection;
    },

    beforeRouteLeave: async function(to, from, next) {
        this.connection.stop();
        next();
    },

    async created() {
        window.addEventListener("beforeunload", this.leaving);
        await this.getAll();
        this.assignInitValues();
        this.$nextTick(() => {
            const tbody = this.$refs.table.$el.querySelector("tbody");
            if (tbody) {
                tbody.addEventListener("keydown", this.handleKeydown);
            }
        });
        this.AddTabIndexes();
    },

    computed: {
        ...mapState({
            files: state => state.supplierInvoice.files,
        }),
        preSelectedSort() {
            if (!this.sortBy) return {};
            return {
                prop: this.sortBy,
                order: this.sortByType,
            };
        },
    },

    beforeCreate() {
        this.$store.commit("supplierInvoice/resetFiles");
    },

    methods: {
        AddTabIndexes() {
            this.$nextTick(() => {
                const rows = this.$refs.table.$el.querySelectorAll("tbody tr");
                rows.forEach(row => {
                    row.setAttribute("tabindex", "0");
                });
            });
        },

        handleRowClick(row) {
            this.$router.push(`/document/${row.documentId}`);
        },

        handleKeydown(event) {
            const row = event.target.closest("tr");
            if (row && event.key === "Enter") {
                const index = Array.from(row.parentNode.children).indexOf(row);
                this.handleRowClick(this.documentsData[index]);
            }
        },
        async removeDocuments() {
            for await (const id of this.selectedIds) {
                await Api.deleteDocument(id);
            }

            this.selectedIds = [];
            this.updateUrlAndGetAll();
        },
        getButtonType(label) {
            switch (label) {
                case "Uploaded":
                    return "info";
                case "WaitingOcr":
                case "WaitingMl":
                    return "warning";
                case "ReadyToBook":
                    return "success";
            }
        },
        getStatusText(label) {
            switch (label) {
                case "Uploaded":
                    return "Uppladad";
                case "WaitingOcr":
                    return "Väntar OCR";
                case "WaitingMl":
                    return "Väntar ML";
                case "ReadyToBook":
                    return "Redo";
                case "HasBeenSupplemented":
                    return "Kompleterad";
            }
        },
        leaving() {
            this.connection.stop();
        },

        goNext() {
            this.$router.push(`/supplierInvoiceUploadedList/`);
        },

        removeFile(key) {
            this.$store.commit("supplierInvoice/removeFiles", key);
        },

        addFiles() {
            this.$refs.files.click();
        },

        handleFileUploads() {
            //validation for duplicate file select
            let uploadedFiles = this.$refs.files.files;
            for (var i = 0; i < uploadedFiles.length; i++) {
                this.$store.commit("supplierInvoice/addFiles", uploadedFiles[i]);
            }
        },

        submitFiles: async function() {
            this.$waiting.start("loading");
            this.spinner = true;
            let formData = new FormData();

            for (var i = 0; i < this.files.length; i++) {
                let file = this.files[i];

                formData.append("files[" + i + "]", file);
            }
            var result = await Api.postSupplierInvoiceFiles(formData);
            this.$notify.success({ title: "Utfört" });
            if (result) {
                this.spinner = false;
                this.isNextDisabled = false;
                this.$store.commit("supplierInvoice/resetFiles");
                this.getAll();
            }
            this.$waiting.end("loading");
            this.uploadingFiles = false;
        },
        searchInputChange() {
            this.$refs.paginationComponent.updateUrl(1);
            this.updateUrlAndGetAll();
        },
        syncDataWithQueryParameters() {
            if (this.isGlobalClientSelected) {
                const { pageNumber } = queryString.parse(location.search);
                this.pageNumber = pageNumber || this.pageNumber;
            } else {
                this.pageNumber = 1;
            }
            setTimeout(() => {
                this.$refs.paginationComponent.updateUrl(+this.pageNumber);
                this.updateUrlAndGetAll();
            }, 200);
        },
        async getAll() {
            this.$waiting.start("loading");
            const response = await Api.getDocuments(this.pageNumber, this.pageSize, this.query, this.sortBy, this.sortByType, this.status, this.currency, this.dateFrom, this.dateTo, this.clientId);
            this.documentsData = response.data;
            this.totalInvoicesNumber = parseInt(response.headers["x-pagination-totalcount"]);
            this.$waiting.end("loading");
        },
        updateUrlAndGetAll() {
            const { pageNumber, pageSize, search, sortBy, sortByType, status, currency, dateFrom, dateTo, clientId } = queryString.parse(window.location.search, { sort: false });
            this.query = search;
            this.pageNumber = pageNumber || this.pageNumber;
            this.pageSize = pageSize || this.pageSize;
            this.sortBy = sortBy || "";
            this.sortByType = sortByType || "";
            this.status = status && status !== "all" ? status : "";
            this.currency = currency || "";
            this.dateFrom = dateFrom || "";
            this.dateTo = dateTo || "";
            this.clientId = clientId || "";
            this.getAll();
        },
        showHoverImage(image) {
            this.$drawer.show(image);
        },
        hideImage() {
            this.$drawer.hide();
        },
        assignInitValues() {
            const { sortBy, sortByType } = queryString.parse(location.search);
            this.sortBy = sortBy;
            this.sortByType = sortByType;
        },

        formatDate(date) {
            if (date == null) return "";
            return moment(date).format("YYYY-MM-DD");
        },

        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            this.$router.replace({ query }).catch(() => {});
            this.syncDataWithQueryParameters();
        },

        handleSelectionChange(val) {
            this.selectedIds = val.map(v => v.documentId);
            console.log(this.selectedIds);
        },
        tableRowClassName() {
            return "table-row";
        },
    },
};
</script>
<style>
.table-row {
    cursor: pointer !important;
}
</style>
<style scoped>
.set-margin {
    margin-left: 3px;
}
.next-btn {
    margin-bottom: 20%;
    margin-right: 40%;
    right: 0;
    bottom: 0;
}
.remove-file {
    cursor: pointer;
    margin-left: 100px;
}
.file-details {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
.add-file {
    display: flex;
    justify-content: space-between;
    width: 24%;
    margin-left: 40px;
    margin-bottom: 30px;
}
.page-header {
    font-size: 35px;
    font-weight: bold;
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    margin-top: 20px;
}
input[type="file"] {
    position: absolute;
    top: -500px;
}
</style>
