String.prototype.toCivicRaw = function() {
    let civic = this.replace(/\D+/g, "");
    if (civic.length === 10) {
        let century = "19";
        let yearNowM9 = (parseInt(new Date().getFullYear()) - 9) % 100;
        let year = parseInt(civic.substr(0, 2));
        if (yearNowM9 >= year) {
            century = "20";
        }
        return century + civic;
    }
    return civic;
};

String.prototype.toCivic11 = function() {
    let civic = this.toCivicRaw();
    return civic.substr(2, 6) + "-" + civic.substr(8, 4);
};

String.prototype.isCivic = function() {
    let civic = this.replace(/\D/g, "");
    if (civic.length !== 12) return false;
    let month = parseInt(civic.substr(4, 2));
    let day = parseInt(civic.substr(6, 2));
    if (month < 1 || month > 12) return false;
    if ((day < 1 || day > 31) && (day < 61 || day > 91)) return false;
    let ssn = civic
        .split("") // convert string to array
        .reverse() // reverse order for Luhn
        .slice(0, 10); // keep only 10 digits (i.e. 1977 becomes 77)

    // verify we got 10 digits, otherwise it is invalid
    if (ssn.length != 10) {
        return false;
    }

    var sum = ssn
        // convert to number
        .map(function(n) {
            return Number(n);
        })
        // perform arithmetic and return sum
        .reduce(function(previous, current, index) {
            // multiply every other number with two
            if (index % 2) current *= 2;
            // if larger than 10 get sum of individual digits (also n-9)
            if (current > 9) current -= 9;
            // sum it up
            return previous + current;
        });

    // sum must be divisible by 10
    return 0 === sum % 10;
};

String.prototype.toNumber = function() {
    if (isNaN(this)) return 0;
    if (this == undefined) return 0;
    if (this == "") return 0;
    return Number(this);
};
Number.prototype.toNumber = function() {
    if (isNaN(this)) return 0;
    if (this == undefined) return 0;
    return this;
};

Number.prototype.roundToEven = function(decimals = 2) {
    var power = Math.pow(10, decimals);
    var value = this * power;
    if (value % 0.5 !== 0) {
        value = Math.round(value);
    } else {
        value = Math.floor(value) % 2 === 0 ? Math.floor(value) : Math.round(value);
    }
    return value / power;
};
// new improved rounding two decimals
Number.prototype.roundToEven2 = function() {
    let value = this * 100;
    const int = Math.floor(value);
    const dec = value * 1000 - int * 1000;
    if (int % 2 !== 0) {
        if (dec >= 500) {
            return (int + 1) / 100;
        }
    } else if (dec > 500) {
        return (int + 1) / 100;
    }
    return int / 100;
};

Number.prototype.x100 = function() {
    if (isNaN(this)) return 0;
    if (this == undefined) return 0;
    return Math.round(this * 100);
};
Number.prototype.toDecimals = function(decimals = 2) {
    return parseFloat(this.toFixed(decimals));
};

// var algorithms = {
//   "round-half-up symmetric": function(value) {
//     if (value >= 0) {
//       return Math.round(value);
//     }

//     return value % 0.5 === 0 ? Math.floor(value) : Math.round(value);
//   },
//   "round-half-up asymmetric (native)": Math.round,
//   "round-half-down symmetric": function(value) {
//     return value % 0.5 === 0 ? (value < 0 ? Math.round(value) : Math.floor(value)) : Math.round(value);
//   },
//   "round-half-down asymmetric": function(value) {
//     return value % 0.5 === 0 ? Math.floor(value) : Math.round(value);
//   },
//   "round-half-even (banker's rounding)": function(value) {
//     if (value % 0.5 !== 0) {
//       return Math.round(value);
//     }

//     return Math.floor(value) % 2 === 0 ? Math.floor(value) : Math.round(value);
//   },
//   "round-half-odd": function(value) {
//     if (value % 0.5 !== 0) {
//       return Math.round(value);
//     }

//     return Math.floor(value) % 2 !== 0 ? Math.floor(value) : Math.round(value);
//   },
//   "round-ceiling": function(value) {
//     return parseInt(value, 10) === value ? value : Math.floor(value + 1);
//   },
//   "round-floor": function(value) {
//     return parseInt(value, 10) === value ? value : Math.round(value - 0.5);
//   },
//   "round-towards-zero": function(value) {
//     return value < 0 ? Math.floor(value + 1) : Math.floor(value);
//   },
//   "round-away-from-zero": function(value) {
//     return parseInt(value, 10) !== value && value > 0 ? Math.floor(value + 1) : Math.floor(value);
//   }
// };
