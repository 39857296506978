import Vue from "vue";
import store from "@/store";
export default {
    getSuppliersList: async search => {
        const response = await Vue.axios.get(`supplierinvoices/suppliersList`, {
            params: {
                search,
            },
        });
        return response.data;
    },
    saveSupplierInvoice: async (tempInvoice, status) => {
        try {
            var response = await Vue.axios.post(`adminsupplierinvoice/saveSupplierInvoice/${status}`, tempInvoice);
        } catch (e) {
            store.commit("supplierInvoice/clearSaveBtnLoading");
        }
        return response.data;
    },
    createReceipt: async tempInvoice => {
        try {
            var response = await Vue.axios.post(`adminsupplierinvoice/createReceipt`, tempInvoice);
        } catch (e) {
            store.commit("supplierInvoice/clearReceiptBtnLoading");
        }
        return response.data;
    },
    bookInvoice: async tempInvoice => {
        try {
            var response = await Vue.axios.post(`adminsupplierinvoice/bookInvoice`, tempInvoice);
        } catch (e) {
            store.commit("supplierInvoice/clearSendBtnLoading");
        }
        return response.data;
    },
    denySupplierInvoice: async (tempInvoice, status) => {
        try {
            var response = await Vue.axios.post(`adminsupplierinvoice/denySupplierInvoice/${status}`, tempInvoice);
        } catch (e) {
            store.commit("supplierInvoice/clearDenyBtnLoading");
        }
        return response.data;
    },
    postSupplierAccount: async searchAccountObj => {
        var response = await Vue.axios.post(`adminsupplierinvoice/postSupplierAccount`, searchAccountObj);
        return response.data;
    },
    getAccountPlan: async () => {
        const response = await Vue.axios.get(`adminsupplierinvoice/getAccounts`);
        return response.data;
    },
    getCurrenciesAsync: async () => {
        const response = await Vue.axios.get(`adminsupplierinvoice/getCurrencies`);
        return response.data;
    },
    copySupplierInvoice: async creditorInvoiceId => {
        var response = await Vue.axios.post(`adminsupplierinvoice/copySupplierInvoice/${creditorInvoiceId}`);
        return response.data;
    },
    postSupplierInvoiceFiles: async formData => {
        const response = await Vue.axios.post(`adminsupplierinvoice/filesUpload`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response;
    },
    postRossumLogin: async () => {
        const response = await Vue.axios.post(`adminsupplierinvoice/rossumLogin`);
        return response.data;
    },
    getUploadDocuments: () => {
        const response = Vue.axios.get(`adminsupplierinvoice/uploadDocuments`);
        return response.data;
    },
    getUploadedDocuments: async () => {
        const response = await Vue.axios.get(`adminsupplierinvoice/getUploadedDocuments`);
        return response.data;
    },
    getValidationScreen: async docId => {
        const response = await Vue.axios.get(`adminsupplierinvoice/getValidationScreen?docId=${docId}`);
        return response.data;
    },
    handleConfirmInvoice: async (docId, status) => {
        const response = await Vue.axios.get(`adminsupplierinvoice/handleConfirmInvoice?docId=${docId}&status=${status}`);
        return response.data;
    },
    getMLResult: async captureId => {
        const response = await Vue.axios.get(`supplierinvoices/mlResult/${captureId}`);
        return response.data;
    },
    getInvoiceImage: async docId => {
        const response = await Vue.axios.get(`adminsupplierinvoice/invoiceImage?docId=${docId}`);
        return response.data;
    },
    getNextQueue: async () => {
        const response = await Vue.axios.get(`adminsupplierinvoice/getNextQueue`);
        return response.data;
    },
    getSelectedClients: async clientName => {
        var response = await Vue.axios.get(`adminsupplierinvoice/getClientsList?clientName=${encodeURIComponent(clientName)}`);
        return response.data;
    },
    createNewSupplier: async supplier => {
        var response = await Vue.axios.post(`adminsupplierinvoice/newSupplier`, supplier);
        return response.data;
    },
    updateSupplier: async supplier => {
        const supplierId = supplier.value;
        var response = await Vue.axios.put(`adminsupplierinvoice/supplier/${supplierId}`, supplier);
        return response.data;
    },
    updateSupplierDefaultAccounts: async (supplierId, defaultAccounts) => {
        var response = await Vue.axios.put(`adminsupplierinvoice/defaultAccounts/${supplierId}`, defaultAccounts);
        return response.data;
    },
    getSuppliers: async supplier => {
        var response = await Vue.axios.post(`adminsupplierinvoice/supplierList`, supplier);
        return response.data;
    },
    getCountryList: async () => {
        var response = await Vue.axios.get(`adminsupplierinvoice/getCountryList`);
        return response.data;
    },
    deleteInvoice: async (id, status) => {
        try {
            var response = await Vue.axios.delete(`adminsupplierinvoice/deleteInvoice/${id}/${status}`);
        } catch {
            this.$store.commit("supplierInvoice/clearDeleteBtnLoading");
        }
        return response.data;
    },
    getCreditInvoicePageList: async (status, clientId, pageNumber, pageSize) => {
        var response = await Vue.axios.get(`adminsupplierinvoice/creditInvoicePageList/${status}/${clientId}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
        return response.data;
    },
    getCreditInvoiceList: async (status, clientId) => {
        var response = await Vue.axios.get(`adminsupplierinvoice/creditInvoiceList/${status}/${clientId}`);
        return response.data;
    },
    getCreditNote: async clientId => {
        var response = await Vue.axios.get(`adminsupplierinvoice/creditNote/${clientId}`);
        return response.data;
    },
    getReceiptInvoices: async clientId => {
        var response = await Vue.axios.get(`adminsupplierinvoice/receiptInvoices/${clientId}`);
        return response.data;
    },
    removeLock: async (lockTable, recordId) => {
        var response = await Vue.axios.get(`adminsupplierinvoice/removeLock/${lockTable}/${recordId}`);
        return response.data;
    },
    checkLock: async (lockTable, recordId) => {
        var response = await Vue.axios.get(`adminsupplierinvoice/checkLock/${lockTable}/${recordId}`);
        return response.data;
    },
    updateCreditNoteStatus: async (id, status) => {
        const response = await Vue.axios.put(`adminsupplierinvoice/updateCreditNoteStatus?id=${id}&status=${status}`);
        return response.data;
    },
    rossumPollAction: async () => {
        const response = await Vue.axios.get(`supplierinvoices/rossumPoll`);
        return response.data;
    },
    getDocumentsToReview: async () => {
        const response = await Vue.axios.get(`supplierinvoices/toReview`);
        return response.data;
    },
    getDocumentsExported: async () => {
        const response = await Vue.axios.get(`supplierinvoices/exported`);
        return response.data;
    },
    getAccruals: async ({ costAccount, amount, invoiceDate, startDate, stopDate }) => {
        const response = await Vue.axios.get(`supplierinvoices/accruals`, { params: { costAccount, amount, invoiceDate, startDate, stopDate } });
        return response.data;
    },
    getHighValueSupplierInvoices: async clientId => {
        const response = await Vue.axios.get(`supplierinvoices/highValueSupplierInvoice/${clientId}`);
        return response.data;
    },
    getSupplierInvoice: async id => {
        const response = await Vue.axios.get(`supplierinvoices/${id}`);
        return response.data;
    },
    approveSupplierInvoice: async (id, approverComment) => {
        try {
            var response = await Vue.axios.put(`supplierinvoices/approve?id=${id}&approverComment=${approverComment}`);
            return response.data;
        } catch (er) {
            return "error";
        }
    },
    getSupplierInvoicesFiltered: async (pageNumber, pageSize, search, sortBy, sortByType, status, currency, dateFrom, dateTo, clientId, test) => {
        const response = await Vue.axios.get(`supplierinvoices/filtered`, {
            params: { pageNumber, pageSize, search, sortBy, sortType: sortByType, status, currency, startDate: dateFrom, endDate: dateTo, clientId, test },
        });
        return response;
    },
    getCounts: async (search, currency, clientId, startDate, endDate) => {
        try {
            const { data } = await Vue.axios.get(`supplierinvoices/counts`, {
                params: {
                    search,
                    currency,
                    startDate,
                    endDate,
                    clientId,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getCounts: ~ error", error);
            return [];
        }
    },
    bookSupplierInvoice: async (id, voucher) => {
        var response = await Vue.axios.post(`supplierinvoices/${id}/book`, voucher);
        return response.data;
    },

};
