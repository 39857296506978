<template>
    <Stack :space="15">
        <Columns align-y="top">
            <Column :width="1 / 2">
                <Stack :space="10">
                    <Stack :space="10">
                        <Heading level="3" color="blue">Ny information</Heading>
                        <Columns :space="5" align-y="top">
                            <Column :width="20"><TextBase size="small" weight="bolder">Namn</TextBase></Column>
                            <Column :width="20"><TextBase size="small" weight="bolder">Personnummer</TextBase></Column>
                            <Column :width="10"><TextBase size="small" weight="bolder">Andel</TextBase></Column>
                            <Column :width="10"><TextBase size="small" weight="bolder">PEP</TextBase></Column>
                            <Column :width="10"><TextBase size="small" weight="bolder">ID</TextBase></Column>
                        </Columns>
                    </Stack>
                    <Control v-bind:key="Id + option.Id + 'D'" v-for="(option, Id) in ownerInformation">
                        <Columns :space="5" align-y="top">
                            <Column :width="20">{{ option.individualName }}</Column>
                            <Column :width="20">{{ option.personalNumber }}</Column>
                            <Column :width="10">{{ option.controlPercentage }}%</Column>
                            <Column :width="10">{{ getEnum.yesNoOption[option.isPoliticallyConnected] }}</Column>
                            <Column :width="10">{{ isID(Id) }}</Column>
                            <span @click="openItem(Id)">
                                <Column :width="10">
                                    <i class="fas fa-chevron-down" :style="option.accordianArrowStyle" />
                                </Column>
                            </span>
                        </Columns>
                        <Stack style="background: #80808061; width: 75%" v-bind:class="{ active: option.accordianArrowStyle == 'transform: rotate(180deg)' }">
                            <Control v-if="option.isopen">
                                <Columns align-y="top">
                                    <Column :width="20"><TextBase size="xsmall" weight="light">Ägs:</TextBase></Column>
                                    <Column :width="20">
                                        <TextBase size="xsmall" weight="light">{{ getEnum.ownershipDetailOptions[option.controlBy] }}</TextBase>
                                    </Column>
                                    <Column v-if="option.controlBy == 2">
                                        <TextBase size="xsmall" weight="light">(Org no: {{ option.organizationNumber }})</TextBase>
                                    </Column>
                                </Columns>
                                <Columns align-y="top" v-if="isStockCompany">
                                    <Column :width="20"><TextBase size="xsmall" weight="light">Medlem i styrelsen:</TextBase></Column>
                                    <Control v-if="!option.addedByAdmin">
                                        <Column :width="5">
                                            <TextBase size="xsmall" weight="light">{{ getEnum.yesNoOption[option.isBoardMember] }}</TextBase>
                                        </Column>
                                        <Column v-if="option.isBoardMember">
                                            <TextBase size="xsmall" weight="light">({{ getEnum.boardOptions[option.boardRole] }})</TextBase>
                                        </Column>
                                    </Control>
                                    <Control v-else>
                                        <Column :width="40">
                                            <Dropdown :options="YesNoOptions" :value="option.isBoardMember" @on-change="value => (option.isBoardMember = value)" />
                                        </Column>
                                    </Control>
                                </Columns>
                                <Columns align-y="top">
                                    <Column :width="20"><TextBase size="xsmall" weight="light">PEP:</TextBase> </Column>
                                    <Column :width="5">
                                        <TextBase size="xsmall" weight="light">{{ getEnum.yesNoOption[option.isPoliticallyConnected] }}</TextBase>
                                    </Column>
                                    <Column v-if="option.isPoliticallyConnected">
                                        <TextBase size="xsmall" weight="light" style="margin-bottom: 10px">
                                            (Typ av utsatt ställning : {{ getEnum.typeOfPoliticalPosition[option.typeOfPoliticalPosition] }})</TextBase
                                        >
                                        <TextBase size="xsmall" weight="light">(Förhållande till personen : {{ getEnum.politicalRelationship[option.politicalRelationship] }})</TextBase>
                                    </Column>
                                </Columns>
                                <Columns align-y="top">
                                    <Column :width="20"><TextBase size="xsmall" weight="light">E-post:</TextBase> </Column>
                                    <Column :width="20">
                                        <TextBase size="xsmall" weight="light">{{ option.email }}</TextBase>
                                    </Column>
                                </Columns>
                                <Columns v-if="idExists(Id)">
                                    <Column>
                                        <a @click="toggleFileDisplay(Id)">Granska ID/LEG </a>
                                    </Column>
                                </Columns>
                                <Columns v-if="idExists(Id)">
                                    <ShowImage style="width: 100%;" :content-url="option.fileByte" :image-type="option.fileType" :show="showFile[Id]" />
                                </Columns>
                            </Control>
                        </Stack>
                    </Control>
                </Stack>
            </Column>
            <Column>
                <Stack :space="10">
                    <Heading level="3" color="blue">Befintlig information</Heading>
                    <Columns :space="5" align-y="top">
                        <Column :width="20"><TextBase size="small" weight="bolder">Namn</TextBase></Column>
                        <Column :width="20"><TextBase size="small" weight="bolder">Personnummer</TextBase></Column>
                        <Column :width="10"><TextBase size="small" weight="bolder">Andel</TextBase></Column>
                        <Column :width="10"><TextBase size="small" weight="bolder">PEP</TextBase></Column>
                    </Columns>

                    <Control v-bind:key="Id + option.Id + 'D'" v-for="(option, Id) in exisitngOwnerInformation">
                        <Columns :space="5" align-y="top">
                            <Column :width="20">{{ option.individualName }}</Column>
                            <Column :width="20">{{ option.personalNumber }}</Column>
                            <Column :width="10">{{ option.controlPercentage }}%</Column>
                            <Column :width="10">{{ getEnum.yesNoOption[option.isPoliticallyConnected] }}</Column>
                        </Columns>
                    </Control>
                </Stack>
            </Column>
        </Columns>
        <Columns>
            <button class="button is-info is-small is-rounded" @click="goback()" style="margin-top:150px">
                Tillbaka
            </button>
            <button class="button is-info is-small is-rounded" :disabled="disableCopyBtn" :class="[{ 'is-loading': loadingCopyBtn }]" @click="copyInformation()" style="margin-top:150px">
                kopiera
            </button>
        </Columns>
    </Stack>
</template>
<script>
import Stack from "../../components/layout/Stack.vue";
import Control from "../../components/layout/Control.js";
import Columns from "../../components/layout/Columns.vue";
import Heading from "../../components/content/Heading.vue";
import Column from "../../components/layout/Column.vue";
import ShowImage from "../../components/showImage.vue";
import TextBase from "../../components/content/Text.vue";
import Api from "../kycByClient/kycByClient.api";
import Enum from "../../pages/onboarding/onboarding.enum";
import KycEnum from "../kycByClient/kycByClient.enum";
export default {
    data() {
        return {
            clientId: undefined,
            file: "",
            fileName: "",
            ownerInformation: [],
            disableCopyBtn: false,
            loadingCopyBtn: false,
            exisitngOwnerInformation: [],
            YesNoOptions: [
                { value: false, name: "Nej" },
                { value: true, name: "Ja" },
            ],
            showFile: [false, false, false, false, false, false, false, false, false, false, false, false],
        };
    },
    components: {
        Stack,
        Control,
        Columns,
        Column,
        Heading,
        ShowImage,
        TextBase,
    },
    async created() {
        this.clientId = this.$route.query.Id;
        if (this.clientId) {
            var individualInformation = await Api.getkycByClientIndividualList(this.clientId);
            if (individualInformation) {
                this.ownerInformation = individualInformation.kycIndividualList;
                this.exisitngOwnerInformation = individualInformation.existingIndividualList;
                if (individualInformation.clientStatus == KycEnum.PageStatus.Godkänd) {
                    this.disableCopyBtn = true;
                }
            }
            this.assignProperties();
        }
    },
    methods: {
        async copyInformation() {
            this.loadingCopyBtn = true;
            var result = await Api.copyData(this.clientId);
            this.$notify.success({ title: "Utfört" });
            if (result) {
                this.disableCopyBtn = true;
                this.loadingCopyBtn = false;
            }
        },
        goback() {
            this.$router.push(`/kycByClientList/`);
        },
        toggleFileDisplay(key) {
            this.$set(this.showFile, key, !this.showFile[key]);
        },
        idExists(key) {
            if (this.ownerInformation[key].fileByte == "" || this.ownerInformation[key].fileByte == undefined) {
                return false;
            }
            return true;
        },
        isID(key) {
            if (this.ownerInformation[key].fileByte != undefined && this.ownerInformation[key].fileByte != "") {
                return "Ja";
            } else return "Nej";
        },
        assignProperties() {
            for (var i in this.ownerInformation) {
                this.$set(this.ownerInformation[i], "isopen", false);
                this.$set(this.ownerInformation[i], "accordianArrowStyle", "transform: rotate(0deg)");
            }
        },
        openItem: function(key) {
            this.ownerInformation[key].isopen = !this.ownerInformation[key].isopen;
            if (this.ownerInformation[key].isopen) {
                this.ownerInformation[key].accordianArrowStyle = "transform: rotate(180deg)";
            } else {
                this.ownerInformation[key].accordianArrowStyle = "transform: rotate(0deg)";
            }
        },
    },
    computed: {
        getEnum() {
            return Enum;
        },
        isStockCompany() {
            if (this.ownerInformation[0].clientType == 1) {
                return false;
            }
            return true;
        },
    },
};
</script>
<style scoped>
.active {
    padding: 10px;
}
</style>
