<template>
    <div>
        <div class="card-tail">
            <TopBar @searchInputChange="searchInputChange" @searchParametersChanged="syncDataWithQueryParameters" />
        </div>
        <div class="card-tail">
            <InpaymentsTable :inpayments-data="inpaymentsData" @sortTypeChanged="syncDataWithQueryParameters" />
            <Pagination ref="paginationComponent" @change="updateUrlAndGetAll" :total-amount="totalInpaymentsNumber" :page-number-prop="pageNumber" />
        </div>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "./inpayments.api.js";
export default {
    data() {
        return {
            inpaymentsData: [],
            totalInpaymentsNumber: 0,
            pageNumber: 1,
            pageSize: 15,
            query: "",
            sortBy: "",
            sortByType: "",
            status: "",
            currency: "",
            dateFrom: "",
            dateTo: "",
            clientId: "",
        };
    },
    components: {
        InpaymentsTable: () => import(/* webpackChunkName: "InpaymentsTable" */ "./components/InpaymentsTable.vue"),
        TopBar: () => import(/* webpackChunkName: "InpaymentsTopBar" */ "./components/TopBar.vue"),
        Pagination: () => import(/* webpackChunkName: "InpaymentsPagination" */ "./components/Pagination.vue"),
    },
    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Inbetalningar");
        document.title = "Inbetalningar";
    },
    async created() {
        if (!this.isGlobalClientSelected) this.updateUrlAndGetAll();
        const currentQuery = { ...this.$route.query };
        if (currentQuery.status == null) {
            currentQuery.status = "inquired";
            this.$router.push({ query: currentQuery });
        }
    },

    methods: {
        searchInputChange() {
            this.$refs.paginationComponent.updateUrl(1);
            this.updateUrlAndGetAll();
        },

        statusToUpperCase(status) {
            switch (status) {
                case "inquired":
                    return "Inquired";
                case "draft":
                    return "Draft";
                case "uploaded":
                    return "Uploaded";
                case "beSupplemented":
                    return "Besupplemented";
                case "adminhandled":
                    return "Adminhandled";
                default:
                    return "";
            }
        },

        syncDataWithQueryParameters() {
            if (this.isGlobalClientSelected) {
                const { pageNumber } = queryString.parse(location.search);
                this.pageNumber = pageNumber || this.pageNumber;
            } else {
                this.pageNumber = 1;
            }
            setTimeout(() => {
                this.$refs.paginationComponent.updateUrl(+this.pageNumber);
                this.updateUrlAndGetAll();
            }, 200);
        },

        updateUrlAndGetAll() {
            const { pageNumber, pageSize, search, sortBy, sortByType, status, dateFrom, dateTo, clientId } = queryString.parse(window.location.search, { sort: false });
            this.query = search;
            this.pageNumber = pageNumber || this.pageNumber;
            this.pageSize = pageSize || this.pageSize;
            this.sortBy = sortBy || "";
            this.sortByType = sortByType || "";
            this.status = this.statusToUpperCase(status);
            this.dateFrom = dateFrom || "";
            this.dateTo = dateTo || "";
            this.clientId = clientId || "";
            this.getAll();
        },

        async getAll() {
            this.$waiting.start("loading");
            const response = await Api.getAll(this.pageNumber, this.pageSize, this.query, this.sortBy, this.sortByType, this.status, this.dateFrom, this.dateTo, this.clientId);
            this.inpaymentsData = response.data;
            this.totalInpaymentsNumber = parseInt(response.headers["x-pagination-totalcount"]);
            this.$waiting.end("loading");
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
