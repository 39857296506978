<template>
    <div>
        <div class="card-tail">
            <TopBar @searchInputChange="searchInputChange" @searchParametersChanged="syncDataWithQueryParameters" />
        </div>
        <div class="card-tail">
            <el-table :default-sort="preSelectedSort" :data="supplierInvoiceData" @sort-change="sortChange" v-loading="$waiting.is('loading')" @selection-change="handleSelectionChange" size="mini">
                <el-table-column prop="id" label="Id" sortable="custom" width="100">
                    <template slot-scope="scope">
                        <div @mouseenter="showHoverImage(scope.row.image)" @mouseleave="hideImage">
                            <span style="color:#415770"> {{ scope.row.id }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="client" label="Klient" sortable="custom" show-overflow-tooltip />
                <el-table-column prop="booked" label="Bokförd" sortable="custom" width="100" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.booked" type="success">
                            Ja
                        </el-tag>
                        <el-tag v-else type="danger">
                            Nej
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="Status" sortable="custom" width="130" align="center">
                    <template slot-scope="scope">
                        <el-tag :type="getStatusTagColor(scope.row.status)">
                            {{ scope.row.status }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="autogiroStatus" label="Autogiro" sortable="custom" width="140" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.autogiroStatus != null" :type="getAutogiroTagColor(scope.row.autogiroStatus)">
                            {{ scope.row.autogiroStatus }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="painStatus" label="PainStatus" sortable="custom" width="130" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.painStatus != null" :type="getPainTagColor(scope.row.painStatus)">
                            {{ scope.row.painStatus }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="supplier" label="Leverantör" sortable="custom" show-overflow-tooltip />
                <el-table-column prop="invoiceNumber" label="Fak nr" sortable="custom" width="150" />
                <el-table-column prop="amount" label="Belopp" sortable="custom" width="100" />
                <el-table-column prop="currency" label="Valuta" sortable="custom" width="100" />
                <el-table-column prop="invoiceDate" label="Fakturadatum" sortable="custom" width="150">
                    <template slot-scope="scope">
                        <p>
                            {{ formatDate(scope.row.invoiceDate) }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="dueDate" label="Förfallodatum" sortable="custom" width="150">
                    <template slot-scope="scope">
                        <p>
                            {{ formatDate(scope.row.dueDate) }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="id" width="50">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="Details" placement="left">
                            <router-link :to="'/supplierInvoice/' + scope.row.id">
                                <el-button type="primary" class="p-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
                                    </svg>
                                </el-button>
                            </router-link>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination ref="paginationComponent" @change="paginationChangePage" :total-amount="totalInvoicesNumber" :page-number-prop="pageNumber" />
        </div>
    </div>
</template>
<script>
import queryString from "query-string";
import moment from "moment";
import Api from "../supplierInvoice.api";

export default {
    components: {
        TopBar: () => import(/* webpackChunkName: "InvoicesTopBar" */ "./TopBar.vue"),
        Pagination: () => import(/* webpackChunkName: "InvoicesPagination" */ "../../invoices/components/Pagination.vue"),
    },

    data() {
        return {
            supplierInvoiceData: [],
            totalInvoicesNumber: 0,
            pageNumber: 1,
            pageSize: 15,
            query: "",
            sortBy: "",
            sortByType: "",
            status: "",
            currency: "",
            dateFrom: "",
            dateTo: "",
            clientId: "",
            test: false,
            loading: true,
            key: 0,
            apiCallPromise: null,
        };
    },

    created() {
        const key = Math.random();
        this.key = key;
        this.updateUrlAndGetAll(key);
        this.assignInitValues();
    },

    computed: {
        preSelectedSort() {
            if (!this.sortBy) return {};
            return {
                prop: this.sortBy,
                order: this.sortByType,
            };
        },
    },

    methods: {
        paginationChangePage() {
            const key = Math.random();
            this.key = key;
            this.updateUrlAndGetAll(key);
        },
        searchInputChange() {
            this.$refs.paginationComponent.updateUrl(1);
            const key = Math.random();
            this.key = key;
            this.updateUrlAndGetAll(key);
        },
        syncDataWithQueryParameters() {
            if (this.isGlobalClientSelected) {
                const { pageNumber } = queryString.parse(location.search);
                this.pageNumber = pageNumber || this.pageNumber;
            } else {
                this.pageNumber = 1;
            }
            setTimeout(() => {
                this.$refs.paginationComponent.updateUrl(+this.pageNumber);
                const key = Math.random();
                this.key = key;
                this.updateUrlAndGetAll(key);
            }, 200);
        },
        async getAll(key) {
            this.$waiting.start("loading");
            const response = await Api.getSupplierInvoicesFiltered(
                this.pageNumber,
                this.pageSize,
                this.query,
                this.sortBy,
                this.sortByType,
                this.status,
                this.currency,
                this.dateFrom,
                this.dateTo,
                this.clientId,
                this.test,
            );
            if (this.key == key){
                this.supplierInvoiceData = response.data;
                this.totalInvoicesNumber = parseInt(response.headers["x-pagination-totalcount"]);
                this.$waiting.end("loading");
            }
        },
        async updateUrlAndGetAll(key) {
            const { pageNumber, pageSize, search, sortBy, sortByType, status, currency, dateFrom, dateTo, clientId, test } = queryString.parse(window.location.search, { sort: false });
            this.query = search;
            this.pageNumber = pageNumber || this.pageNumber;
            this.pageSize = pageSize || this.pageSize;
            this.sortBy = sortBy || "";
            this.sortByType = sortByType || "";
            this.status = status && status !== "all" ? status : "";
            this.currency = currency || "";
            this.dateFrom = dateFrom || "";
            this.dateTo = dateTo || "";
            this.clientId = clientId || "";
            this.test = test || false;
            await this.getAll(key);
        },
        showHoverImage(image) {
            this.$drawer.show(image);
        },
        hideImage() {
            this.$drawer.hide();
        },
        getStatusTagColor(label) {
            switch (label) {
                case "Utkast":
                    return "warning";
                case "Attesteras":
                    return "";
                case "VäntarBokförd":
                    return "";
                case "VäntarBetalning":
                    return "";
                case "Betald":
                    return "success";
                case "Nekad":
                    return "danger";
                case "Kompleteras":
                    return "warning";
                case "Kompleterad":
                    return "";
                case "Deleted":
                    return "danger";
                case "AdminNekad":
                    return "danger";
                case "KvittoSkapat":
                    return "info";
                case "Uploaded":
                    return "info";
                case "InväntarAMLAttest":
                    return "info";
            }
        },
        getAutogiroTagColor(label) {
            switch (label) {
                case "Utförd":
                    return "success";
                case "SaknarTeckning":
                    return "danger";
                case "Skickad":
                    return "warning";
            }
        },
        getPainTagColor(label) {
            switch (label) {
                case "Success":
                    return "success";
                case "Denied":
                    return "danger";
                case "Error":
                    return "danger";
                case "Sent":
                    return "success";
                case "Cancelled":
                    return "info";
                case "Waiting":
                    return "warning";
            }
        },
        assignInitValues() {
            const { sortBy, sortByType } = queryString.parse(location.search);
            this.sortBy = sortBy;
            this.sortByType = sortByType;
        },

        formatDate(date) {
            if (date == null) return "";
            return moment(date).format("YYYY-MM-DD");
        },

        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            this.$router.replace({ query }).catch(() => {});
            this.syncDataWithQueryParameters();
        },

        handleSelectionChange() {},
    },
};
</script>
<style>
.el-popover__title {
    color: #fff;
}

.drawer {
    width: 30%;
    height: 100%;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
}
</style>
