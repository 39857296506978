import Vue from "vue";
export default {
    getOnbaordingClientList: async () => {
        const response = await Vue.axios.get(`onboarding/onboardClientList`);
        return response.data;
    },
    getOnbaordingClientDetails: async onboardClientId => {
        const response = await Vue.axios.get(`onboarding/onboardClientDetails?onboardClientId=${onboardClientId}`);
        return response.data;
    },
    getCompanyInfo: async onboardClientId => {
        const response = await Vue.axios.get(`onboarding/getCompanyInfo?onboardClientId=${onboardClientId}`);
        return response.data;
    },
    fetchCheckbiz: async (orgNo, onboardId) => {
        const response = await Vue.axios.get(`onboarding/fetchCheckbiz?orgNo=${orgNo}&onbaordId=${onboardId}`);
        return response.data;
    },
    addCheckBizOwner: async (personalNumber, fullname, onboardId) => {
        const response = await Vue.axios.post(`onboarding/addCheckBizOwner?personalNumber=${personalNumber}&fullname=${fullname}&onboardId=${onboardId}`);
        return response.data;
    },
    saveByAdmin: async onboardOwner => {
        const response = await Vue.axios.post(`onboarding/saveByAdmin`, onboardOwner);
        return response.data;
    },
    fetchBeneficiaryCheckbiz: async (orgNo, onboardId) => {
        const response = await Vue.axios.get(`onboarding/fetchBeneficiaryCheckbiz?orgNo=${orgNo}&onboardId=${onboardId}`);
        return response.data;
    },
    getOperationsData: async onboardClientId => {
        const response = await Vue.axios.get(`onboarding/operations?onboardClientId=${onboardClientId}`);
        return response.data;
    },
    getCm1: async onboardId => {
        const response = await Vue.axios.get(`onboarding/cm1?onboardId=${onboardId}`);
        return response.data;
    },
    saveInfo: async tempData => {
        var response = await Vue.axios.post(`onboarding/save`, tempData);
        return response.data;
    },
    createClient: async tempData => {
        var response = await Vue.axios.post(`onboarding/createClient`, tempData);
        return response.data;
    },
    getSniCodeData: async sniCodeJson => {
        const response = await Vue.axios.get(`onboarding/sniCode?sniCode=${sniCodeJson}`);
        return response.data;
    },
    deleteOwnerInfo: async (onbaordId, ownerId) => {
        var response = await Vue.axios.delete(`onboarding/${onbaordId}/${ownerId}`);
        return response.data;
    },
    deleteInProgressClient: async onbaordClientId => {
        var response = await Vue.axios.delete(`onboarding/inProgressClient/${onbaordClientId}`);
        return response.data;
    },
};
